import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import "./login.scss";
import poweredLogo from "../../assets/img/aaneel-poweredby.svg";
import aaneelLogo from "../../assets/img/aaneelcare-blue-logo.png";
const LOGIN_URL = "/auth";

const Login = () => {
  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const userManager = new UserManager(oidcConfig);
  const [userName, setUserName] = useState("");
  const performSSOLogout = async () => {
    try {
      const user = await userManager.getUser();
      if (!user || !user.id_token) {
        console.error("No valid user or ID Token found");
        return;
      }
      await userManager.signoutRedirect({
        id_token_hint: user.id_token,
        post_logout_redirect_uri: `${window.location.origin}/auth-callback-logout`,
      });

      iframeRef.current.src = userManager.createSignoutRequest({}).url;
    } catch (error) {
      console.error("SSO Logout Error:", error);
    }
  };
  useEffect(() => {
    async function fetchUserName() {
      try {
        const user = await userManager.getUser();
        if (user) {
          if (user.profile.fullname) {
            setUserName(user.profile.fullname);
          } else {
            setUserName(user.profile.given_name);
          }
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    }
    fetchUserName();
  }, [userManager]);

  return (
      <div className='theme-authentication-screen'>
        <div className='theme-container'>
          <div className='theme-authentication-main'>
            <div className='theme-authentication-left'>
              <p>Healthcare Management Solution</p>
            </div>
            <div className='theme-authentication-right'>
              <div className='theme-logo'>
                <img src={aaneelLogo} alt='Aaneel Care logo' />
                <span className='theme-version' id='versionLabel'>
                  Version 1.0
                </span>
              </div>
              <span className='alpha-beta-text' ng-if='environmentType'>
                BETA
              </span>
              <div className='theme-title-main'>
                <h1 className='theme-title'>Login</h1>
              </div>
              <div className='theme-welcome-text'>
                <h2 className='theme-title'>Welcome to AaNeelCare</h2>
                {/* <span>Login to your account</span> */}
              </div>
              <div className='theme-information-message'>
                <span>
                  User (<b>{userName}</b>) does not have access to this
                  application.
                  <Link to='#' onClick={performSSOLogout}>
                    Click Here
                  </Link>
                  to login with different user.
                </span>
                <p>
                  Note: This action will log you off from all AaNeel products.
                </p>
              </div>
              <div className='copyright-poweredby'>
                <p className='theme-copyright'>© 2023 AaNeel Infotech LLC</p>
                <div className='theme-powered-by'>
                  <span>Powered by</span>
                  <img src={poweredLogo} alt='Aaneel logo' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Login;