import { urls } from "../Urls";
import { Http } from "../Http";
export class ApplicationApi {
  static getregisterdApps(payerID:any,pageNumber:number,pageSize:number){
    const url = urls.applications.getRegisterApplicaiton(payerID,pageNumber,pageSize)
    return Http.get(url)
  }
 static getApplictionById(id:number){
  const url = urls.applications.getApplicationById(id)
  return Http.get(url)
 }
static updateApplicationStatus(data:any){
  return Http.put(``,data)
}
}