import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Application.scss";
import applogo from "../../assets/img/image_fill.svg";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getApplicationByID,
  updateApplicationStatus,
  getRegisterApplication,
} from "../../redux/slice/ApplicationSlice";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ApplicationView = () => {
  const dispatch = useDispatch();
  const loc = useLocation();
  const { appId } = loc.state || {};
  const data = useSelector(
    (state) => state?.applications?.applicationByID?.data
  );
  const declineReasons = data?.deniedReson?.split(',') || [];
  const navigate = useNavigate();
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: "Application Name", checked: false },
    { id: 2, label: "Environment", checked: false },
    { id: 3, label: "Callback URLs / Redirect URLs", checked: false },
    { id: 4, label: "App Logo", checked: false },
    { id: 5, label: "Application Description", checked: false },
    { id: 6, label: "Organization / Company Website URL", checked: false },
    { id: 7, label: "Organization Type", checked: false },
    { id: 8, label: "Privacy Policy URL", checked: false },
    { id: 9, label: "Terms of service URL", checked: false },
    { id: 10, label: "Customer Support Email Address", checked: false },
    { id: 11, label: "Customer Support Phone Number", checked: false },
    { id: 12, label: "Application Development Team Contacts", checked: false },
    { id: 13, label: "Other", checked: false },
  ]);
  const [isDecline, setIsDecline] = useState(false);
  const [status, setStatus] = useState(0);
  const [isReload, setIsReload] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const [isPending, setIsPending] = useState(false);

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxes.map((cb) =>
      cb.id === id ? { ...cb, checked: !cb.checked } : cb
    );
    setCheckboxes(updatedCheckboxes);
    const selectedCheckboxes = updatedCheckboxes
      .filter((cb) => cb.checked)
      .map((cb) => cb.label)
      .join(", ");

    setTextareaValue(selectedCheckboxes);
  };

  const resetCheckboxes = () => {
    const resetCheckboxes = checkboxes.map((cb) => ({ ...cb, checked: false }));
    setCheckboxes(resetCheckboxes);
  };

  useEffect(() => {
    dispatch(getApplicationByID(appId));
  }, [isReload]);

  const handleUpdateApplicationStatus = async (newStatus) => {
    const payload = {
      appId: appId,
      environment: data?.environment,
      payerID: data?.payerID,
      applicationStatus: newStatus, // use newStatus instead of status
      deniedReson: textareaValue,
    };
    setIsPending(true);
    dispatch(updateApplicationStatus(payload)).then((response) => {
      if (response.payload) {
        dispatch(getRegisterApplication());
        setIsReload(!isReload);
        setIsPending(false);
      }
    });
  };

  const handleApprove = () => {
    const newStatus = 1; // Status for approval
    setStatus(newStatus); // Update the state
    handleUpdateApplicationStatus(newStatus); // Pass the new status to the function
  };

  const handleDecline = () => {
    const newStatus = 3; // Status for decline
    setStatus(newStatus); // Update the state
    handleUpdateApplicationStatus(newStatus); // Pass the new status to the function
  };

  const statusMapping = {
    Pending: {
      icon: "fh_pending_process_line",
      text: "Pending",
      className: "status pending",
    },
    Approved: {
      icon: "fh_check_circle_line",
      text: "Approve",
      className: "status approve",
    },
    Denied: {
      icon: "fh_close_circle_line",
      text: "Decline",
      className: "status decline",
    },
  };
  const applicationStatus = data?.applicationStatusDescription;
  const currentStatus = statusMapping[applicationStatus] || {};
  const [disabledButtons, setDisabledButtons] = useState({});
  const handleCopy = (url, tooltipId) => {
    navigator.clipboard.writeText(url);

    setDisabledButtons((prev) => ({ ...prev, [tooltipId]: true }));

    setTimeout(() => {
      setDisabledButtons((prev) => ({ ...prev, [tooltipId]: false }));
    }, 3000); // Reset after 3 seconds
  };
  const CopyButton = ({ url, tooltipId }) => (
    <div
      data-tooltip-id={tooltipId}
      data-tooltip-content={!disabledButtons[tooltipId] ? 'Copy URL' : ''}
      data-tooltip-place="top"
      className="tooltip-wrapper"
    >
      <button
        type="button"
        className={`btn btn-outline-light ${disabledButtons[tooltipId] ? 'btn-disabled' : ''}`}
        onClick={() => handleCopy(url, tooltipId)}
        disabled={disabledButtons[tooltipId]}
      >
        <i className="fh_icons">fh_copy</i>
      </button>
      <Tooltip id={tooltipId} place="top" effect="solid" />
    </div>
  );

  return (
    <>
      <div className="main-content">
        <div className="content-header">
          <button
            type="button"
            className="btn btn-border-primary h-48 d-flex align-items-center"
            onClick={() => navigate("/ApplicationList")}
          >
            <i className="fh_arrow_left icon-mr"></i>
            <span>Back to application list</span>
          </button>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="card-content">
              <div className="row">
                <h2 className="col-12 col-xxl-3 headers">
                  App Details - Required Information
                </h2>
                <div className="col-12 col-xxl-9">
                  <div className="row">
                    <div className="col-12 content-box">
                      <div className="img-info">
                        <i className="fh_icons">fh_application_window</i>
                      </div>
                      <div className="content-info">
                        <div className="label">Application Name</div>
                        <div className="value status-info">
                          {data?.applicationName}

                          <div className={currentStatus.className}>
                            {currentStatus.icon && (
                              <span>
                                <i className="fh-icons">{currentStatus.icon}</i>
                                {currentStatus.text}
                              </span>
                            )}
                          </div>
                          {data?.applicationStatusDescription === "Denied" && (
                            <div className="dropdowninfo">
                              <i
                                className="fh-icons"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                fh_info_line
                              </i>
                              <ul className="dropdown-menu">
                                <li>
                                  <h6 className="dropdown-header p-xxl-0">
                                    Application Pending Reasons
                                  </h6>
                                </li>
                                {declineReasons.map((reason, index) => (
            <li key={index}>
              <span className="dot"></span>{reason.trim()}
            </li>
          ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 content-box">
                      <div className="img-info">
                        <i className="fh_icons">fh_secure_sheild</i>
                      </div>
                      <div className="content-info">
                        <div className="label">QAuth - Client Types</div>
                        <div className="value">{data?.clientType}</div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 content-box">
                      <div className="img-info">
                        <i className="fh_icons">fh_check_decagram</i>
                      </div>
                      <div className="content-info">
                        <div className="label">Authorization Grant Type</div>
                        <div className="value">{data?.grantType}</div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 content-box">
                      <div className="img-info">
                        <i className="fh_icons">fh_double_settings</i>
                      </div>
                      <div className="content-info">
                        <div className="label">Environment</div>
                        <div className="value">{data?.environment}</div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-8 content-box">
                      <div className="img-info">
                        <i className="fh_icons">fh_id_card</i>
                      </div>
                      <div className="content-info">
                        <div className="label">Client ID</div>
                        <div className="value">
                          {data?.clientID ? data.clientID : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 content-box">
                      <div className="img-info">
                        <i className="fh_icons">fh_key_fill</i>
                      </div>
                      <div className="content-info">
                        <div className="label">Client Secret</div>
                        <div className="value">
                          {data?.secretKey ? data?.secretKey : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 content-box">
                      <div className="img-info">
                        <i className="fh_icons">fh_check_decagram</i>
                      </div>
                      <div className="content-info">
                        <div className="label">
                          Callback URLs / Redirect URLs

                        </div>
                        <div className="value link">
                          <div className="link-ref">
                            {data?.callbackURLs && data.callbackURLs.length > 0 ? (
                              data.callbackURLs.map((url, index) =>
                                url.length > 1 && (
                                  <div className="text-custom-center" key={index}>
                                    <div className="mr-24">{url}</div>
                                    <div className="copy-icon">
                                      <CopyButton
                                        url={url}
                                        tooltipId={`callback-tooltip-${index}`}
                                        tooltipMessage="Copy URL"
                                      />
                                    </div>
                                  </div>
                                )
                              )
                            ) : (
                              <div className="value">N/A</div>
                            )}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="hidden-divider d-xxl-none"></div>
                <h2 className="col-12 col-xxl-3 headers">Other Information</h2>
                <div className="col-12 col-xxl-9">
                  <div className="row">
                    <div className="divider d-none d-xxl-block"></div>
                    <h2 className="sub-header p-xxl-0">
                      App Logo & Descriptions
                    </h2>
                    <div className="col-12 content-box">
                      <div className="img-info">
                        <img
                          src={data?.logo ? data?.logo : applogo}
                          alt="applogo"
                        ></img>
                      </div>
                      <div className="content-info">
                        <div className="label">App Logo</div>
                        <div className="value">{data?.applicationName}</div>
                      </div>
                    </div>
                    <div className="col-12 content-box custom-mar">
                      <div className="img-info">
                        <i className="fh_icons">fh_create</i>
                      </div>
                      <div className="content-info">
                        <div className="label">Application Discription</div>

                        <div className="value">
                          {data?.description ? data?.description : "N/A"}
                        </div>
                      </div>
                    </div>
                    <h2 className="sub-header p-xxl-0">
                      Organization & Policy Info
                    </h2>
                    <div className="col-12 content-box custom-mar">
                      <div className="img-info">
                        <i className="fh_icons">fh_link</i>
                      </div>
                      <div className="content-info">
                        <div className="label">
                          Organization / Company Website URL
                        </div>
                        <div className="value link">
                          <div className="link-ref">
                            {data?.websiteURL ? data?.websiteURL : "N/A"}
                          </div>
                          {data?.websiteURL && (
                            <div className="copy-icon">
                              <CopyButton
                                url={data?.websiteURL}
                                tooltipId="tooltip-websiteURL"
                                tooltipMessage="Copy Website URL"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <h2 className="sub-header p-xxl-0">Organization Type</h2>
                    <div className="col-12 content-box">
                      <div className="img-info">
                        <i className="fh_icons">team_cordination_line_2</i>
                      </div>
                      <div className="content-info">
                        <div className="label">Organization Type</div>
                        <div className="value">
                          {data?.organizationTypeDescription
                            ? data?.organizationTypeDescription
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 content-box">
                      <div className="img-info">
                        <i className="fh_icons">fh_link</i>
                      </div>
                      <div className="content-info">
                        <div className="label">Privacy Policy URL</div>
                        <div className="value link">
                          <div className="link-ref">
                            {data?.privacyPolicyURL
                              ? data?.privacyPolicyURL
                              : "N/A"}
                          </div>
                          {data?.privacyPolicyURL && (
                            <div className="copy-icon">
                              <CopyButton
                                url={data?.privacyPolicyURL}
                                tooltipId="tooltip-privacyPolicyURL"
                                tooltipMessage="Copy Privacy Policy URL"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 content-box custom-mar">
                      <div className="img-info">
                        <i className="fh_icons">fh_link</i>
                      </div>
                      <div className="content-info">
                        <div className="label">Terms of Service URL</div>
                        <div className="value link">
                          <div className="link-ref">
                            {data?.termsOfServiceURL
                              ? data?.termsOfServiceURL
                              : "N/A"}
                          </div>
                          {data?.termsOfServiceURL && (
                            <div className="copy-icon">
                              <CopyButton
                                url={data?.termsOfServiceURL}
                                tooltipId="tooltip-termsOfServiceURL"
                                tooltipMessage="Copy Terms of Service URL"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <h2 className="sub-header p-xxl-0">Contact Information</h2>
                    <div className="col-12 content-box">
                      <div className="img-info">
                        <i className="fh_icons">fh_alternate_email</i>
                      </div>
                      <div className="content-info">
                        <div className="label">
                          Customer Support Email Address
                        </div>
                        <div className="value">
                          {data?.supportEmail ? data?.supportEmail : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 content-box">
                      <div className="img-info">
                        <i className="fh_icons">fh_call_support</i>
                      </div>
                      <div className="content-info">
                        <div className="label">
                          Customer Support Phone Number
                        </div>
                        <div className="value">
                          {data?.supportPhone ? data?.supportPhone : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 content-box">
                      <div className="img-info">
                        <i className="fh_icons">fh_user_call</i>
                      </div>
                      <div className="content-info">
                        <div className="label">
                          Application Development Team Contacts
                        </div>
                        <div className="value link">
                          <div className="link-ref">
                            {data?.devTeamContacts && data.devTeamContacts.length > 0 ? (
                              data.devTeamContacts.filter(url => url.length > 1).map((url, index) => (
                                <div key={index} className="text-custom-center">
                                  <div className="mr-24">{url}</div>
                                  <div className="copy-icon">
                                    <CopyButton
                                      url={url}
                                      tooltipId={`devteam-tooltip-${index}`}
                                      tooltipMessage="Copy URL"
                                    />
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="value">N/A</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="second-divider"></div>
                    {!isDecline && (
                      <>
                        {data?.applicationStatusDescription === "Pending" && (
                          <div className={`col-12 custom-mar p-xxl-0 ${!isDecline ? 'm-0' : ''}`}>
                            <button
                              type="button"
                              className="btn btn-primary h-48 me-3 img-btn"
                              onClick={handleApprove}
                            >
                              <i className="fh_icons">fh_thumb_up</i>
                              {isPending ? <CircularProgress /> : "Approve"}
                            </button>
                            <button
                              type="button"
                              className="btn btn-border-primary h-48 img-btn decline"
                              onClick={() => setIsDecline(!isDecline)}
                            >
                              <i className="fh_icons">fh_thumb_down</i>
                              Decline
                            </button>
                          </div>
                        )}
                      </>
                    )}


                    {isDecline && (
                      <>
                        <h3 className="mini-header p-xxl-0">
                          Please select the reason to decline application
                        </h3>
                        <div className="custom-multiselect-checkbox p-xxl-0">
                          <div
                            className="btn-group"
                            role="group"
                            aria-label="Basic checkbox toggle button group"
                          >
                            {checkboxes &&
                              checkboxes.map((checkbox) => (
                                <div key={checkbox.id}>
                                  <input
                                    type="checkbox"
                                    className="btn-check"
                                    id={`btncheck${checkbox.id}`}
                                    autoComplete="off"
                                    checked={checkbox.checked}
                                    onChange={() =>
                                      handleCheckboxChange(checkbox.id)
                                    }
                                  />
                                  <label
                                    className="btn btn-outline-primary"
                                    htmlFor={`btncheck${checkbox.id}`}
                                  >
                                    {checkbox.checked && (
                                      <i className="fh_icons">fh_check_done</i>
                                    )}
                                    {checkbox.label}
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                        <h3 className="mini-header  p-xxl-0">
                          Describe the reason
                        </h3>
                        <div className="textarea-info p-xxl-0">
                          <textarea
                            className="custom-textarea"
                            placeholder="Describe the reason here…"
                            rows="4"
                            value={textareaValue}
                            onChange={(e) => setTextareaValue(e.target.value)}
                          ></textarea>
                          <i className="fh-icons">fh_create</i>
                        </div>

                        <div className="col-12 p-xxl-0">
                          <button
                            type="button"
                            className="btn btn-primary h-48 me-3"
                            onClick={() => {
                              handleDecline();
                              setIsDecline(!isDecline);
                              setTextareaValue("");
                              resetCheckboxes();
                            }}
                            disabled={
                              textareaValue === "" || textareaValue === null
                                ? true
                                : false
                            }
                          >
                            {isPending ? <CircularProgress /> : "Submit"}
                          </button>
                          <button
                            type="button"
                            className="btn btn-border-primary h-48"
                            onClick={() => {
                              setIsDecline(!isDecline);
                              setTextareaValue("");
                              resetCheckboxes();
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationView;
