import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  useLocation,
} from "react-router-dom";
import { AuthProvider, useAuth, userRole } from "./context/auth-context";
import { useEffect } from "react";
import useAutoLogout from "./hooks/useAutoLogout";
// Components
import LeftBar from "./components/leftBar/LeftBar";
import NavBar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import AuthCallback from "./components/Auth/auth-callback";
import AuthCallbackLogout from "./components/Auth/auth-callback-logout";
import AuthCallbackSilent from "./components/Auth/auth-callback-silent";
// Pages
import Changepassword from "./pages/users/Changepassword";
import SignUpForm from "./pages/register/signup";
import Login from "./pages/login/Login";
import PatientSearch from "./pages/Patients/patientSearch";
import ApplicationList from "./pages/application/Application-list";
import ApplicationView from "./pages/application/Application-view";
import UserSearch from "./pages/users/UserSearch";
import UserDetail from "./pages/users/UserDetail";
import "./scss/_main.scss";
import "./scss/_theme.scss";
import "./App.css";
import Forgetpassword from "./pages/users/Forgetpassword";
import PostLogout from "./pages/login/PostLogout";
import { Provider } from "react-redux";
import store from "./redux/store";
function Layout() {
  const publicRoutes = [
    "/createaccount",
    "/auth-callback",
    "/auth-callback-logout",
    "/auth-callback-silent",
    "/forgotpassword",
    "/login",
    "/post-logout",
  ];
  const { user, userManager, isLoading } = useAuth(); // Get isLoading from context
  const location = useLocation();
  const clientName = process.env.REACT_APP_ClientName;
  if (clientName === "freedom") {
    document.body.classList.add("theme-freedom");
    document.body.classList.remove("theme-healthsun", "theme-optimum");
  } else if (clientName === "healthson") {
    document.body.classList.add("theme-healthsun");
    document.body.classList.remove("theme-freedom", "theme-optimum");
  }else if (clientName === "demo") {
    document.body.classList.add("theme-freedom");
    document.body.classList.remove("theme-healthsun", "theme-optimum");
  }
  else {
    document.body.classList.add("theme-optimum");
    document.body.classList.remove("theme-healthsun", "theme-freedom");
  }

  const handleLeftPanel = () => {
    if (document.body.classList.contains("sb-sidenav-toggled")) {
      document.body.classList.toggle("sb-sidenav-toggled");
      localStorage.setItem(
        "sb|sidebar-toggle",
        document.body.classList.contains("sb-sidenav-toggled")
      );
    }
  };
  //useAutoLogout();
  useEffect(() => {
    const checkUser = async () => {
      if (!user && !isLoading && !publicRoutes.includes(location.pathname)) {
        try {
          userManager.signinRedirect();
        } catch (error) {
          console.error("Redirection to login failed:", error);
          // If silent authentication fails, use a full redirect
          await userManager.signinRedirect({
            state: { returnPath: location.pathname },
          });
        }
      }
    };
    checkUser();
  }, [user, userManager, location.pathname, isLoading]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user && !publicRoutes.includes(location.pathname)) {
    userManager.signinRedirect(); // Redirect to SSO login if the user isn't authenticated
    return <div>Redirecting...</div>;
  }

  return (
    <>
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <LeftBar />
        </div>
        <div id="layoutSidenav_content">
          <NavBar />
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
        <div className="overlay-backdrop" onClick={handleLeftPanel}></div>
      </div>
    </>
  );
}

function App() {
  return (
   <AuthProvider>
      <Provider store={store}>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/createaccount" element={<SignUpForm />} />
          <Route path="/auth-callback" element={<AuthCallback />} />
          <Route path="/auth-callback-logout" element={<AuthCallbackLogout />}/>
          <Route path="/auth-callback-silent" element={<AuthCallbackSilent />}/>
          <Route path="/forgotpassword" element={<Forgetpassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/post-logout" element={<PostLogout />} />
          {/* Protected Routes */}
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<PatientSearch />} />
            <Route path="changepassword" element={<Changepassword />} />
            <Route path="PatientSearch" element={<PatientSearch />} />
            <Route path="ApplicationList" element={<ApplicationList />} />
            <Route path="ApplicationView" element={<ApplicationView />} />
            <Route path="UserSearch" element={<UserSearch />} />
            <Route path="UserDetail" element={<UserDetail />} />
            <Route path="/UserDetail/:userId" element={<UserDetail />} />
          </Route>
        </Routes>
      </Router>
      </Provider>
    </AuthProvider>
  );
}
export default App;