import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth-context";

function AuthCallback() {
  const { userManager, setUser, setIsLoading } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const handleSignIn = async () => {
      try {
        setIsLoading(true);
        const user = await userManager.signinRedirectCallback();
        const access_token = user.access_token;
        const returnPath =
          user && user.state && user.state.returnPath
            ? user.state.returnPath
            : "/";
        setIsLoading(false);
        navigate(returnPath);
      } catch (error) {
        if (error && error.message === "invalid_grant") {
        } else {
          console.error("Error during sign-in callback:", error);
        }
      }
    };
    handleSignIn();
  }, [userManager, navigate]);
  return <div>Loading...</div>;
}
export default AuthCallback;
