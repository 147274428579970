import { configureStore } from "@reduxjs/toolkit";
import registerdAppReducer from './slice/ApplicationSlice'

export const resetAction = () => ({
  type: "RESET_STATE",
});
export const store = configureStore({
  reducer: {
   applications:registerdAppReducer, 
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: true,
    })
      .concat
      (),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;